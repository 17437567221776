.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f2f5f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 78%; /* Adjust as needed */

  overflow: auto; /* Add this line to enable scrollbar */
  resize: none;
  border: 2px solid #85c743;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #050505;
}

.title{
  color: #344854;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}


.background-image {
  user-select: none;
  pointer-events: none;
}

.image-container {
  user-select: none;
  pointer-events: none;
}



.button-overlay {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 78%; 
  height: 50%;
  resize: none;
  border-radius: 4px;
  font-size: 16px; 
  margin-top: 333px;
  background-color: #197bf8;
}



.text-overlay {
  /* ... other styles ... */

  overflow-y: scroll; /* Show scrollbar always */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set thumb color to transparent when not scrolling */
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #85c743; /* Set thumb color on hover */
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
